@charset "utf-8";

//
// Bulma
//
@import "../node_modules/bulma-scss/bulma.scss";

body {
  min-width: 320px;
}

.card {
  margin: 0 auto;
  padding: 2rem 1.5rem;
  height: 100%;
  @media screen and (min-width: 960px) {
    padding: 2.5rem;
  }
  h3 {
    margin: 0;
  }
}
a {
  display: block;
}

.column {
  flex-grow: 1 !important;
}

.hidden {
  opacity: 0;
  visibility: hidden;
}
.welcome-copy {
  font-size: $size-normal;
  @media screen and (min-width: 960px) {
    font-size: $size-medium;
  }
}
.elsewhere-page {
  hr {
    background-color: #8d4bf6;
  }
  .card {
    cursor: pointer;
    background-color: #8d4bf6;
    transition: background-color 0.5s ease-in-out;
    h3,
    small {
      color: #fff;
    }
    &:hover {
      background-color: #bb94fa;
      h3 {
        color: #fff;
      }
    }
  }
}
.los-angeles-page {
  &.outside-page {
    hr {
      background-color: #f7ffcc;
    }
    .card {
      background-color: #f7ffcc;
      &:hover {
        background-color: #fbffe6;
      }
    }
  }
  &.inside-page {
    hr {
      background-color: #00aa13;
    }
    .card {
      background-color: #00aa13;
      h3 {
        color: #fff;
      }
      &:hover {
        background-color: #00dd19;
      }
    }
  }
  .card {
    cursor: pointer;
    transition: background-color 0.5s ease-in-out;
  }
}

.minneapolis-page {
  &.inside-page {
    hr {
      background-color: #1125aa;
    }
    .card {
      cursor: pointer;
      transition: background-color 0.5s ease-in-out;
      background-color: #1125aa;
      h3 {
        color: #fff;
      }
      &:hover {
        background-color: #162fd8;
        h3 {
          color: #fff;
        }
      }
    }
  }

  &.outside-page {
    hr {
      background-color: #cce6ff;
    }
    .card {
      cursor: pointer;
      transition: background-color 0.5s ease-in-out;
      background-color: #cce6ff;
      h3 {
        color: hsl(0, 0%, 21%);
      }
      &:hover {
        background-color: #e6f3ff;
        h3 {
          color: hsl(0, 0%, 21%);
        }
      }
    }
  }
}

.column {
  a {
    height: 100%;
  }
}
